import React, { useState } from 'react';
import EmailLoginManager from "./EmailLoginManager";
import { Input, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom"
import { useCookies } from "react-cookie";

const Login = props => {
  const history = useHistory()
  const manager = new EmailLoginManager()
  let [email, setEmail] = useState("")
  let [message, setMessage] = useState("")
  let [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [cookies] = useCookies([]);

  const onLogin = async () => {
    setMessage("")
    setErrorMessage("")
    setIsLoading(true)
    try {
      let ret = await manager.requestLogin(email)
      setMessage(ret.detail)
      setIsLoading(false)
      history.push("/RegisterEmailSuccess/")
    } catch (e) {
      if(e.response.status === 404){
        history.push("/EmailRegister/")
      }
      setErrorMessage(JSON.stringify(e.response.data))
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if(cookies.apiToken){
      props.onHaveApiToken()
    }
  }, [cookies.apiToken])

  React.useEffect(() => {
    setMessage("")
    setErrorMessage("")
  }, [])

  return (
    <div className="email-login">
      <br />
      <br />
      <h3>
        ลงชื่อเข้าใช้งานด้วยอีเมล
      </h3>
      <br />
      <br />
      <Input className="email-input" placeholder="Email Address" required value={email} onChange={e => setEmail(e.target.value)} />
      <br />
      <br />
      <Button className="login-button" loading={isLoading} disabled={isLoading} onClick={onLogin} content="Login" />
      <div>
        <br />
        {errorMessage && (
          <div className="err-message">
            {errorMessage}
          </div>
        )}
        <br />
        {/* <div className="sub-text">
          หากยังไม่เคยลงทะเบียนเข้าใช้งาน กรุณา Click <span dangerouslySetInnerHTML={{ __html: "<a href='/EmailRegister/'>ลงทะเบียน</a>" }} />
        </div> */}
      </div>
    </div>
  )
}

Login.defaultProps = {
  onHaveApiToken: () => {},
};

Login.propTypes = {
  onHaveApiToken: PropTypes.func,
};

export default Login
