import React, {
  CSSProperties,
  useRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import {
  Event as MobEvent,
  State as MobState,
} from "../patient-lib/MobTransformInterface";

import { useHistory, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import * as MODEL from "./Model";
import MaterialButton from "@material-ui/core/Button";
import { Height, Style, Tune } from "@material-ui/icons";
import { DidMount } from "./MobTransformInterface";
import {
  Form,
  Button,
  Modal,
  Icon,
  Loader,
  TextArea,
  Image,
  Item,
  Dimmer,
} from "semantic-ui-react";

type ConfirmShippingProps = {
  patientData: MODEL.PatientData;
  onEvent: (e: MobEvent) => any;
  confirmShipping: any;
  shippingList: any;
  setQRData: any;
  selectedOrder: any;
};

const icon = {
  drug: "/icon_white_medication.png",
  cancel: "/icon_cancel_white.png",
};

const ConfirmShipping: React.FunctionComponent<ConfirmShippingProps> = (
  props
) => {
  const history = useHistory();
  const chatListRef = useRef<any>();
  const [comfirmShipping, setComfirmShipping] = React.useState(false);
  const [checkOrder, setCheckOrder] = React.useState(false);
  const [shippingList, setShippingList] = useState<any>();
  const [shippingOder, setShippingOder] = useState<any>(null);
  const [encounter, setEncounter] = useState<any>(null);

  const [confirmDate, setConfirmDate] = useState<String>("");
  const [confirmTime, setConfirmTime] = useState<String>("");
  const [isLoading , setIsLoading] = useState(true);

  const beToday = moment();
  const beFormatdate = "DD/MM/YYYY";
  const beFormat = "hh:mm";

  const COLOR = {
    primary: "var(--primary-theme-color)",
    secondary: "#F2C94C",
    violet_blue: "#2D247F",
    font_white: "white",
    dark_grey: "#333333",
    orange_light: "#EB5757",
    skybule: "#EFE4F2",
    bule_tu: "#90419A",
    red: "#D90416",
    green: "#26A653",
  };

  const styles = {
    logo:{
      display: "flex",
      justifyContent: "center",
      marginBottom: "50px",
      marginTop: "20px",
    } as CSSProperties,

    label: {
      color: COLOR.primary,
      fontSize: "33px",
      marginBottom: "50px",
      marginTop: "50px",
      textAlign: "left",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
    } as CSSProperties,

    labelNormal: {
      color: "black",
      fontSize: "20px",
      textAlign: "left",
      marginTop: "8px",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
    } as CSSProperties,
    
    labelText: {
      color: "black",
      fontSize: "16px",
      marginTop: "40px",
      textAlign: "left",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
    } as CSSProperties,

    normalText: {
      color: "black",
      fontSize: "18px",
      marginBottom: "15px",
      marginTop: "15px",
      textAlign: "left",
      fontWeight: "bold",
      display: "flex",
    } as CSSProperties,

    button: {
      background: COLOR.primary,
      color: COLOR.font_white,
      fontSize: "16px",
      padding: "15px 0",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    } as CSSProperties,

    button_rounded: {
      background: COLOR.primary,
      color: "white",
      fontSize: "18px",
      height: "50px",
      width: "85%",
      marginBottom: "70px",
      borderRadius: "50rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    } as CSSProperties,

    backgroundDetail: {
      borderRadius: "20px",
      margin: "30px",
      padding: "15px 25px",
      background: COLOR.skybule,
      marginBottom: "auto",
    } as CSSProperties,
    
  };

  useEffect(() => {
    setComfirmShipping(false);
    setIsLoading(true)
    
    if(props.selectedOrder !== null) {
      setEncounter(props.selectedOrder.encounter);
      setShippingOder(props.selectedOrder.shipping);
    } else {
      if (typeof window.MobNative !== "undefined") {
        window.MobNative.getScanQR();
      } else if (typeof window.iosNative !== "undefined") {
        window.iosNative.getScanQR();
      }
    }

    props.onEvent({
      message: "HandleShippingOderlist",
      params: { patient: props.patientData.patient },
    });
  }, []);

  useEffect(() => {
    if (
      props.confirmShipping !== null &&
      props.confirmShipping.detail === "Success"
    ) {
      setComfirmShipping(true);
      setConfirmDate(props.confirmShipping.date)
      setConfirmTime(props.confirmShipping.time)
    } else {
      setComfirmShipping(false);
      setCheckOrder(false);
    }
  }, [props.confirmShipping]);

  useEffect(() => {
    if (props.shippingList !== null && props.shippingList !== undefined) {
      const orderFiter: any = props.shippingList.filter(
        (item: any) => (item.parcel_id === encounter));
        console.log(`chen orderFiter `, orderFiter);
      if (orderFiter[0] !== undefined) {
        if (
          orderFiter[0]?.status_label !== "จัดส่งสำเร็จ" &&
          orderFiter[0]?.status_label !== undefined
        ) {
          setIsLoading(false)
          setCheckOrder(true);
          setShippingList(orderFiter[0]);
          setEncounter(orderFiter[0].parcel_id);
        } else {
          setIsLoading(false)
          setComfirmShipping(true);
          setCheckOrder(true);
          setShippingList(orderFiter[0]);
          setEncounter(orderFiter[0].parcel_id);
          checkDateTime(orderFiter[0].edited);
        }
      } else {
        setCheckOrder(false);
      }
    } else {
      setCheckOrder(false);
    }
  }, [props.shippingList,encounter,shippingOder]);

  useEffect(() => {
    console.log("check qrDataSent : 3", props.setQRData);
    if (props.setQRData !== null) {
      const qrData = props.setQRData;
      setEncounter(qrData.encounter);
      setShippingOder(qrData.shipping);
    }
  }, [props.setQRData]);

  const HandleShippingOrder = () => {
    if (comfirmShipping) {
      history.push(`/tuh-transform/MakeAppointment/${shippingOder}/`);
    } else {
      props.onEvent({
        message: "HandleShippingOrder",
        params: { encounter: encounter, shipping: shippingOder },
      });
    }
  };

  const checkDateTime = (time: string) => {
    const date = moment(time).format(beFormatdate);
    const timer = moment(time).format(beFormat);
    setConfirmDate(date);
    setConfirmTime(timer);
  };

  
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
              {checkOrder ? (
          <>
            <div style={styles.label}>
              {comfirmShipping ? "รับยาเรียบร้อยแล้ว" : "ยืนยันการรับยา"}
            </div>
            <div style={styles.logo}>
              <div
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50px",
                  background: comfirmShipping ? COLOR.green : COLOR.bule_tu,
                  padding: "20px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {comfirmShipping ? (
                  <Icon size="huge" inverted name="check">
                    {" "}
                  </Icon>
                ) : (
                  <Image src={icon.drug} />
                )}
              </div>
            </div>
            <div style={styles.backgroundDetail}>
              <div style={styles.normalText}>
                รายการยา : {shippingList ? ` ${shippingList.parcel_id}` : " -"}
              </div>
              <div style={styles.normalText}>
                ซื่อผู้รับยา :{" "}
                {shippingList ? ` ${shippingList.receiver_name}` : " -"}
              </div>
              <div style={styles.normalText}>
                วันที่รับยา :{" "}
                {comfirmShipping
                  ? confirmDate
                    ? ` ${confirmDate}`
                    : ` -`
                  : ` ${beToday.format(beFormatdate)}`}
              </div>
              <div style={styles.normalText}>
                เวลารับยา :{" "}
                {comfirmShipping
                  ? confirmTime
                    ? ` ${confirmTime}`
                    : ` -`
                  : ` -`}{" "}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              { (!shippingList.is_make_appointment)?  
                <Button
                  onClick={() => HandleShippingOrder()}
                  style={styles.button_rounded}
                >
                  {comfirmShipping ? "ทำนัดหมาย" : "ยืนยันการรับยา"}
                </Button> : null
                }
            </div>
          </>
        ) : (
          <>
            <div style={styles.label}>ไม่สามารถรับยาได้</div>
            <div style={styles.logo}>
              <div
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50px",
                  background: COLOR.red,
                  padding: "30px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image src={icon.cancel} />
              </div>
            </div>
            <div style={styles.labelNormal}>{"เนื่องจากรายการนี้"} </div>
            <div style={styles.labelNormal}>
              {"ไม่ได้อยู่ในประวัติการจ่ายยาของท่าน"}{" "}
            </div>
            <div style={styles.labelText}>
              {"หากต้องการสอบถามกรุณาติดต่อเจ้าหน้าที่"}{" "}
            </div>
          </>
        )}
    </div>
  );
};

ConfirmShipping.defaultProps = {
  patientData: {},
  onEvent: () => {},
  confirmShipping: null,
  shippingList: [],
  setQRData: null,
  selectedOrder: null,
} as ConfirmShippingProps;

export default React.memo(ConfirmShipping);
