import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Icon, Divider, Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import ModConfirm from "../common/ModConfirm";

interface RouterProps {}

interface CardPatientAppointmentProps {
  editAble?: boolean;
  patientAppointmentList?: any[];
  handleGoBack?: any;
  getPatientAppointmentList?: any;
  onEdit?: any;
  onCancel?: any;
  setProps?: any;
  onEvent?: any;
  patientId?: any;
  viewIndex?: any;
}

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    padding: "10px",
    overflow: "auto",
    heigth: "100%",
    width: "100%",
  },
  cardDiv: {
    padding: "5px",
    margin: "8px 0px",
    width: "100%",
    border: "2px solid #D3D3D3",
    borderRadius: "5px",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.25)",
  },
  columnDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  columnDivAlignLeftPadding: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    padding: "0px 5px",
  },
  columnDivAlignLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
  },
  rowDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "5px 0px",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  doctorHeader: {
    fontSize: "18px",
    color: "var(--primary-theme-color)",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  contentBlue: {
    fontSize: "16px",
    color: "var(--primary-theme-color)",
    padding: "5px 0px",
  },
  contentGrey: {
    fontSize: "16px",
    color: "#746A6A",
    padding: "5px 0px",
  },
  flexWidthAuto: {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    paddingRight: "12px",
    fontSize: "16px",
  },
}));

const CardPatientAppointment = (props: CardPatientAppointmentProps) => {
  const classes = useStyles();
  const [expandedItem, setExpandedItem] = useState<any>([]);
  const [modConfirm, setModConfirm] = useState<any>({
    open: false,
    confirm: false,
    selectedItem: {},
  });
  const [dataList, setDataList] = useState<any>([]);

  useEffect(() => {
    setDataList(props.patientAppointmentList);
  }, [props.patientAppointmentList]);

  const handleClickItemExpand = (itemId: any) => {
    let expandTemp = [...expandedItem];
    if (expandTemp.includes(itemId)) {
      expandTemp = expandTemp.filter((item: any) => item != itemId);
    } else {
      expandTemp.push(itemId);
    }
    setExpandedItem(expandTemp);
  };

  const handleClickCancel = (item: any) => {
    setModConfirm({
      open: true,
      confirm: false,
      selectedItem: { ...item },
    });
  };

  const handleConfirmCancel = () => {
    props.onCancel(modConfirm.selectedItem);
    props.onEvent({
      message: "HandleCancelAppointment",
      params: {
        id: modConfirm.selectedItem?.id,
        patientId: props.patientId,
        viewIndex: props.viewIndex,
      },
    });
    setDataList([]);
    setExpandedItem([]);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setModConfirm({
      open: false,
      confirm: false,
      selectedItem: {},
    });
  };

  return (
    <div className={classes.mainDiv}>
      {dataList
        ?.filter((item: any) => item?.status !== 5)
        ?.map((item: any, index: number) => (
          <CardPatientAppointmentItem
            key={`patientAppointment-${item.id}`}
            showExpaned={props.editAble}
            patientAppointment={item}
            isExpanded={expandedItem.includes(item.id)}
            onClickExpand={() => {
              handleClickItemExpand(item.id);
            }}
            onEdit={() => {
              props.onEdit(item);
            }}
            onCancel={() => {
              handleClickCancel(item);
            }}
          />
        ))}
      <ModConfirm
        open={modConfirm.open}
        // alertText="ต้องการออกจากระบบใช่หรือไม่"
        titleName={""}
        alertText={"ต้องการยกเลิกนัดหมายนี้หรือไม่"}
        buttonLeft={<FormattedMessage id="common.cancel" />}
        buttonRight={<FormattedMessage id="common.confirm" />}
        onButtonLeftClick={handleCloseModal}
        onButtonRightClick={() => {
          handleConfirmCancel();
        }}
      />
    </div>
  );
};

interface CardPatientAppointmentItemProps {
  // key?: any;
  patientAppointment?: any;
  showExpaned?: boolean;
  isExpanded?: boolean;
  onClickExpand?: any;
  onEdit?: any;
  onCancel?: any;
}

const CardPatientAppointmentItem = (props: CardPatientAppointmentItemProps) => {
  const classes = useStyles();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    handleSetData(props.patientAppointment);
  }, [props.patientAppointment]);

  const handleSetData = (patientApp: any) => {
    let tempData = {
      id: patientApp?.id || null,
      doctor_full_name: "",
      division_name: "",
      date: "",
      time: "",
      is_telemed: patientApp?.is_telemed,
      note: patientApp?.order_note || "-",
      emergency_contact: patientApp?.emergency_contact,
      emergency_telephone: "",
    };

    if (patientApp?.doctor_full_name) {
      tempData["doctor_full_name"] = patientApp?.doctor_full_name;
    }

    if (patientApp?.display_info?.division_name) {
      tempData["division_name"] = patientApp?.display_info?.division_name;
    }

    let startDate = moment(patientApp?.estimated_at_iso);
    if (startDate) {
      let year = parseInt(startDate.format("YYYY")) + 543;
      tempData["date"] = `${startDate.format("DD MMMM ")}${year}`;
      tempData["time"] = startDate.format("HH:mm");
    }

    if (patientApp?.emergency_contact_phone_no) {
      let phone_no = patientApp?.emergency_contact_phone_no;
      tempData["emergency_telephone"] = `${phone_no.slice(
        0,
        3
      )}-${phone_no.slice(3, 10)}`;
    }
    setData(tempData);
  };

  return (
    <div className={classes.cardDiv}>
      <div className={classes.columnDiv}>
        <div className={classes.columnDivAlignLeftPadding}>
          <div className={classes.doctorHeader}>{data?.doctor_full_name}</div>
          <div className={classes.contentGrey}>{data?.division_name}</div>
          <div className={classes.rowDiv} style={{ color: "#746A6A" }}>
            <div className={classes.flexWidthAuto}>
              <Icon name="calendar alternate outline" />
              <div>{data?.date}</div>
            </div>
            <div className={classes.flexWidthAuto}>
              <Icon name="clock outline" />
              <div>{data?.time}</div>
            </div>
            <div className={classes.flexWidthAuto}>
              {data?.is_telemed ? (
                <Icon name="video camera" />
              ) : (
                <Icon name="hospital outline" />
              )}
            </div>
          </div>

          {props.showExpaned && (
            <div className={classes.flexEnd}>
              <Icon
                style={{ color: "#746A6A" }}
                size="big"
                name={props.isExpanded ? "angle up" : "angle down"}
                onClick={props.onClickExpand}
              />
            </div>
          )}
        </div>
        {props.showExpaned && props.isExpanded && (
          <div className={classes.columnDiv}>
            <Divider style={{ width: "100%", padding: "0px 15px" }} />
            <div className={classes.columnDivAlignLeftPadding}>
              <div className={classes.contentBlue}>รายละเอียดเพิ่มเติม</div>
              <div className={classes.contentGrey}>{data?.note}</div>
              <div className={classes.contentBlue}>ผู้ติดต่อกรณีฉุกเฉิน</div>
              <div className={classes.contentGrey}>
                {data?.emergency_contact}
              </div>
              <div className={classes.contentGrey}>
                {data?.emergency_telephone}
              </div>
            </div>
            <div className={classes.flexEnd}>
              <Button color="blue" onClick={props.onEdit}>
                <FormattedMessage id="common.edit" />
              </Button>
              <Button color="orange" onClick={props.onCancel}>
                <FormattedMessage id="common.cancel" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CardPatientAppointment.defaultProps = {
  editAble: false,
  patientAppointmentList: [],
  noApiToken: () => {},
  match: null,
  onEdit: () => {},
  onCancel: () => {},
};

export default CardPatientAppointment;
