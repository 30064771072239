import BaseService from "./BaseService";
import { QUE } from "../../configs/apis";
class QUEService extends BaseService {
  createServiceSlot({ apiToken, data, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(QUE.SERVICE_SLOT, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  updateServiceSlot({ apiToken, pk, data, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = QUE.SERVICE_SLOT_UPDATE({
      pk
    });
    return this.client
      .put(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  loadServiceSlot({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(QUE.SERVICE_SLOT, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  loadDivisionServiceBlock({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    // let url
    // if (to_serial) {
    //   url = QUE.DIVISION_SERVICE_BLOCK +
    //           `?from_serial=${from_serial}&to_serial=${to_serial}&divisions=${divisions.join(",")}`
    // } else {
    //   url = QUE.DIVISION_SERVICE_BLOCK +
    //   `?from_serial=${from_serial}&divisions=${divisions.join(",")}`
    // }
    return this.client
      .get(QUE.DIVISION_SERVICE_BLOCK, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDivisionServiceBlockDetail({ apiToken, pk } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = QUE.DIVISION_SERVICE_BLOCK_DETAIL + `${pk}/`;
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  listPatientAppointment({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(QUE.PATIENT_APPOINTMENT, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPatientAppointment({ apiToken, pk, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = QUE.PATIENT_APPOINTMENT_DETAIL({
      pk
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  updatePatientAppointment({ apiToken, pk, data, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = QUE.PATIENT_APPOINTMENT_DETAIL({
      pk
    });
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPatientAppointmentUpdate({ apiToken, appointmentId, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = QUE.PATIENT_APPOINTMENT_DETAIL({
      pk: appointmentId
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default QUEService;
