import React from "react";
import { Image, Form, Icon } from "semantic-ui-react";
import personImg from "../../common/person.png";
import PropTypes from "prop-types";
import { convertToBEDate } from "../../../utils/tsUtils";
import { isValidHttpUrl } from "./MyMessage";
import { processjson } from "wasm-lib";

const FriendMessage = (props) => {
  // console.log(" FriendMessage ", props)
  const createMessage = () => {
    if (props.message.content_type === "file") {
      return (
        <div className="file">
          <div>
            <Icon name="file" size="big" />
          </div>
          <div className="text">Click to open file</div>
        </div>
      );
    } else if (props.message.content_type === "image") {
      return (
        <Image
          src={props.message.content_file}
          // href={props.isMobile ? props.message.content_file: ""}
          onClick={() => {
            // if (typeof window.iosNative !== "undefined") {
            //   window.iosNative.consoleLog("onClick");
            //   window.iosNative.saveImage(props.message.content_file);
            // } else {
            if (!props.isMobile) {
              window.open(props.message.content_file);
            }
            // }
          }}
        />
      );
    } else if (props.message.content_type === "vid_call") {
      return <Icon name="call square" size="big" color="green" />;
    } else if (props.message.content_type === "end_call") {
      return <Icon name="call square" size="big" color="gray" />;
    } else if (props.message.content_type === "navigation") {
      return props.onNavigationMessage({
        content: props.message.content,
        contentPayload: props.message.content_payload,
        channelId: props.message.channel,
      });
    } else if (props.message.content_type === "bill") {
      return props.onNavigationMessage({
        content: props.message.content,
        contentPayload: props.message.content_payload,
        contentType: props.message.content_type,
      });
    } else {
      if (
        props.acceptContentHTML &&
        props.message.content?.trim().startsWith("<div") &&
        props.message.content?.trim().endsWith("</div>")
      ) {
        // It's bot
        // console.log("props.message.content", props.message.content);
        return (
          <div
            style={{ color: "#4F4F4F" }}
            dangerouslySetInnerHTML={{ __html: props.message.content }}
          />
        );
      } else {
        // Not Bot
        if (props.message.content.includes("http")) {
          let listMsg = props.message.content.split(" ");
          // console.log("listMsg", listMsg);
          let Linkify = listMsg.map((item) => {
            // console.log("listMsg item: ", item)
            if (!item.startsWith("http") && item.includes("http")) {
              let startIdx = item.indexOf("http");
              // console.log("listMsg startIdx: ", startIdx)
              if (startIdx > 0) {
                let normalText = item.slice(0, startIdx);
                // console.log("normalText:", normalText);
                let urlText = item.slice(startIdx);
                // console.log("urlText:", urlText);

                // workaround issue 52375
                var urlLinkText = item;
                if (isValidHttpUrl(item)) {
                  var prepareUrl = new URL(item);
                  if (prepareUrl.host === "penguin.ishealth.app") {
                    prepareUrl.host = "mybplus.web.app";
                    urlLinkText = prepareUrl.toString() + "/?app=IH";
                  } else if (
                    prepareUrl.host === "staging.penguin.ishealth.app"
                  ) {
                    prepareUrl.host = "bplus-expansion-staging.web.app/";
                    urlLinkText = prepareUrl.toString() + "/?app=IH";
                  }
                  // console.log(" urlLinkText", urlLinkText)
                }

                return (
                  <>
                    <>
                      {normalText}

                      <a href={urlLinkText} style={{ position: "relative" }} onClick={props.onClickLink} onMouseDown={props.onClickLink} onContextMenu={props.onClickLink}>{urlLinkText}</a>
                    </>
                  </>
                );
              }
            } else {
              // console.log("item: ", item)
              // workaround issue 52375
              var urlLinkText = item;
              if (isValidHttpUrl(item)) {
                var prepareUrl = new URL(item);
                if (prepareUrl.host === "penguin.ishealth.app") {
                  prepareUrl.host = "mybplus.web.app";
                  urlLinkText = prepareUrl.toString() + "/?app=IH";
                } else if (prepareUrl.host === "staging.penguin.ishealth.app") {
                  prepareUrl.host = "bplus-expansion-staging.web.app/";
                  // prepareUrl.host = "192.168.11.206:3000/"
                  urlLinkText = prepareUrl.toString() + "/?app=IH";
                }
                // console.log(" urlLinkText", urlLinkText)
              }

              return isValidHttpUrl(item) ? (
                <a href={urlLinkText} style={{ position: "relative" }} onClick={props.onClickLink} onMouseDown={props.onClickLink} onContextMenu={props.onClickLink}> {urlLinkText} </a>
              ) : (
                <> {item} </>
              );
            }
          });

          return Linkify;
        } else {
          return props.message.content;
        }
      }
    }
  };

  return (
    <Form className="FriendMessage">
      {!props.isBot && props.message.author ? (
        <Form.Field className="friendName">
          <b>{props.message.author.full_name}</b>
        </Form.Field>
      ) : null}

      <Form.Group
        inline
        style={{
          alignItems: "flex-start",
          flexWrap: "nowrap",
          flexDirection: "row",
        }}
      >
        {!props.isBot && (
          <Form.Field width={1} className="avatarImage">
            <Image
              src={
                props.message.author && props.message.author.image
                  ? props.message.author.image
                  : personImg
              }
              circular
              size="mini"
              // onMouseOver={() => console.log(9999)}
            />
          </Form.Field>
        )}

        {props.isBot && (
          <Form.Field
            style={{
              maxWidth: "50px",
              minWidth: "50px",
              padding: "0px 4px 0px 0px",
            }}
          >
            <Image src={props.avatarBotUrlString} circular />
          </Form.Field>
        )}

        <Form.Field
          className={"content" + (props.isBot ? " chatbot" : "")}
          {...(props.isMobile &&
            props.message.content_type === "file" && {
              href: props.message.content_file,
            })}
          onClick={() => {
            if (props.message.content_type == "vid_call") {
              props.onOpenVideoCall({ url: props.message.content });
            }
            if (props.message.content_type === "file") {
              window.open(props.message.content_file);
            }
            if (props.isMobile && props.message.content_type === "image") {
              props.onMobileOpenImage(props.message.content_file);
            }
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "0.3rem",
            }}
          >
            <div className="bubble">{createMessage()}</div>
            <div className="sendDateTime">
              <div> {props.message.send_at.split(" ")[1]}</div>
              <div>
                {" "}
                {convertToBEDate({
                  date: props.message.send_at.split(" ")[0],
                })}{" "}
              </div>
            </div>
          </div>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

FriendMessage.defaultProps = {
  avatarBotUrlString: "",
  isBot: false,
  isMobile: false,
  onOpenVideoCall: ({ url }) => {},
  onNavigationMessage: ({ content = "" }) => content,
  onMobileOpenImage: () => {},
  acceptContentHTML: false,
  onClickLink: PropTypes.func,
};

FriendMessage.propTypes = {
  avatarBotUrlString: PropTypes.string,
  isBot: PropTypes.bool,
  isMobile: PropTypes.bool,
  onOpenVideoCall: PropTypes.func,
  onNavigationMessage: PropTypes.func,
  acceptContentHTML: PropTypes.bool,
  onClickLink: PropTypes.func,
};

export default React.memo(FriendMessage);
