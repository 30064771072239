import axios from "axios";
import querystring from "querystring";
import settings from "../../../../../config/config";
import Cookies from "js-cookie";

class AppleIDLoginError extends Error {
  constructor(message, response) {
    super();
    this.message = message;
    this.response = response;
  }
}

export default class AppleIDLoginManager {
  constructor() {
    this.clientId = settings.APPLE_ID_CLIENT_ID;
    this.redirectURI = settings.APPLE_ID_REDIRECT_URI;
    this.auth = null;
  }

  /**
   * Generate random uuid version 4
   * @returns {string}
   */
  uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === "x" ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }


  /**
   * Get CSRF Token which act as state for OAuth.
   * This will create token and store it to cookie.
   * If a token is already exist in cookie,
   * return a value from cookie
   * @returns {string}
   */
  getCSRFState = () => {
    let token = Cookies.get("x-csrftoken");
    if (!token) {
      token = this.uuidv4();
      Cookies.set("x-csrftoken", token);
    }
    return token;
  }

  /**
   * Check that given token equal to CSRF token stored in cookie
   * @param token {string}
   * @returns {boolean}
   */
  verifyCSRFState = (token) => {
    return token === this.getCSRFState();
  }

  getToken = async (code) => {
    this.auth = {
      token: code
    };
    return this.auth.token;
  }

  getTokenFromAccount = async (app, email, subscription, device_id) => {
    const server = "https://penta.ishealth.app/apis/register_by_token/apple-id/";
    // const server = 'http://127.0.0.1:8000/apis/register_by_token/apple-id/'
    if (this.auth === null) {
      throw new AppleIDLoginError("you have to call getToken() first", null);
    }

    let params = {
      access_token: this.auth.token,
      app: app,
      device_token: JSON.stringify(subscription),
      unique_id: device_id,
      device_id: device_id,
      device_type: "webpush"
    };

    const query = querystring.stringify(params);
    if (email) {
      query["email"] = email;
    }
    try {
      let ret = await axios.get(`${server}?${query}`);
      return ret.data;
    } catch (e) {
      console.error(e.response);
      throw new AppleIDLoginError(e.response.data);
    }
  };
}
