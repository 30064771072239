import React from "react";
import PropTypes from "prop-types";

import { Button, Confirm } from "semantic-ui-react";

class BtnCommonConfirm extends React.Component<any, any> {

  static propTypes: any;
  static defaultProps: any;
  
  constructor(props: any) {
    super(props);
    this.state = {
      confirmOpen: false,
      cancelConfirmOpen: false
    };
  }

  handleConfirmOpen = () => this.setState({ confirmOpen: true });
  handleConfirmClose = () => this.setState({ confirmOpen: false });
  handleCancelConfirmOpen = () => this.setState({ cancelConfirmOpen: true });
  handleCancelConfirmClose = () => this.setState({ cancelConfirmOpen: false });

  handelConfirm = () => {
    this.props.onConfirm();
    this.setState({ confirmOpen: false });
  };

  render() {
    let button;
    button = (
      <div style={this.props.style} >
        <Button
          style={{width:'100%'}}
          type="Button"
          color={this.props.btnColor}
          onClick={this.handleConfirmOpen}
        >
          {this.props.btnText}
        </Button>
        <Confirm
          open={this.state.confirmOpen}
          onCancel={this.handleConfirmClose}
          onConfirm={this.handelConfirm}
          size={"tiny"}
          content={this.props.contentText}
          style={{ width: "300px", margin: "-150px" }}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          cancelButton={"ยกเลิก"}
          confirmButton={this.props.btnConfirmText}
        />
      </div>
    );
    return button;
  }
}

BtnCommonConfirm.defaultProps = {
  onConfirm: () => {},
  btnText: "",
  btnColor: "",
  contentText: "",
  style: {},
  btnConfirmText: "ตกลง",
};

BtnCommonConfirm.propTypes = {
  onConfirm: PropTypes.func,
  btnText: PropTypes.string,
  btnColor: PropTypes.string,
  contentText: PropTypes.string,
  style: PropTypes.object,
  btnConfirmText: PropTypes.string,
};

export default BtnCommonConfirm;
