import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";
import { serial_to_datestr, PatientAppointment, QueueController } from "./Time";
import "./Time.css";
import Cookies from "js-cookie";
import * as MODEL from "./Model";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import ModPatientEditAppointment from "./ModPatientEditAppointment";
import * as Util from "../../utils/tsUtils";
import moment from "moment";
import { FormattedMessage } from "react-intl";

interface RouterProps {

}
interface TimePatientProps extends RouteComponentProps<RouterProps> {
  apiToken: string;
  patientData: MODEL.PatientData;
  controller: QueueController;
  handleGoBack: () => {};
  noApiToken: () => {};
  header: React.Component;
  displayDatetimeIso?: boolean;
}

const TimePatient = (props: TimePatientProps) => {
  const history = useHistory();
  // const [cookies] = useCookies([]);
  const isMounted = React.useRef(true);
  const [editAppointmentId, setEditAppointmentId] = useState<null | number>(
    null
  );
  const [editAppointmentDivision, setEditAppointmentDivision] = useState<
    null | number
  >(null);
  const [openModEditAppointment, setOpenModEditAppointment] = useState(false);
  const [patientAppointmentList, setPatientAppointmentList] = useState<
    PatientAppointment[]
  >([]);
  const [isLoading, setIsLoading] = useState(true)

  React.useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (props.patientData.id) {
      handleListPatientAppointment();
    } else {
      setIsLoading(false)
    }
  }, [props.patientData.id]);

  const handleListPatientAppointment = async () => {
    setIsLoading(true)
    const [
      response,
      error,
      network
    ] = await props.controller.listPatientAppointment({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientId: props.patientData.id
    });
    if (isMounted.current) {
      setIsLoading(false)
      if (response) {
        let newArr: PatientAppointment[] = response.items.sort(
          (
            a: { display_info: { start_serial: number } },
            b: { display_info: { start_serial: number } }
          ) => {
            if (!a.display_info && !b.display_info) return 0;
            else if (!a.display_info) return -1;
            else if (!b.display_info) return 1;
            else
              return a.display_info.start_serial - b.display_info.start_serial;
          }
        );

        console.log(" newArr", newArr)
        setPatientAppointmentList(newArr);
      } else {
        setPatientAppointmentList([]);
      }
    }
  };

  const handleEditAppointment = ({
    appointmentId,
    appointmentDivision,
    providerTypeCategory
  }: {
    appointmentId: number;
    appointmentDivision: number;
  }) => {

    console.log(" handleEditAppointment providerTypeCategory ", providerTypeCategory, appointmentId,  appointmentDivision )
    if (providerTypeCategory === "covid_vaccine") {
      let locationState = props.location.state;
      locationState.providerTypeCategory = "covid_vaccine";
      history.push(`/Appointment/${appointmentId}/`, props.location.state);
    } else {
      setEditAppointmentId(appointmentId);
      setEditAppointmentDivision(appointmentDivision);
      setOpenModEditAppointment(true);
    }
  };

  const handleCloseModEditAppointment = () => {
    setEditAppointmentId(null);
    setOpenModEditAppointment(false);
  };

  const createEstimateTime = ({ time }: { time: null | string }) => {
    console.log(time);
    if (time) {
      let estimateService = moment(time);
      let localTime = estimateService.isValid()
        ? ` ${estimateService.format("HH:mm")} น.`
        : "";
      return localTime;
    }
    return "";
  };

  return (
    <div className="TimePatient">
    {props.header}
    <Dimmer.Dimmable>
      <Dimmer active={isLoading} inverted>
        <Loader inverted/>
      </Dimmer>
      <ModPatientEditAppointment
        open={openModEditAppointment}
        onClose={handleCloseModEditAppointment}
        controller={props.controller}
        appointmentId={editAppointmentId}
        division={editAppointmentDivision}
      />
      {patientAppointmentList.length > 0 && <h1> <FormattedMessage id="appoint.appointment_list" /></h1>}
      {patientAppointmentList.map((p, index) => {
        return (
          <div key={index} className="appointCard">
            <div className="header-and-button">
              <h2>
                {p.display_info
                  ? p.display_info.provider_name
                  : <FormattedMessage id="appoint.unspecified" />}
              </h2>
              {!p.display_info && (
                <Button
                  className="addButton"
                  compact
                  onClick={(e: any) => {
                    console.log("props.location.state", props);
                    history.push(`/Appointment/${p.id}/`, props.location.state);
                  }}
                >
                  <FormattedMessage id="appoint.specify_appointment" />
                </Button>
              )}
              {p.display_info && (
                <Button
                  className="changeButton"
                  compact
                  onClick={() =>
                    handleEditAppointment({
                      appointmentId: p.id,
                      appointmentDivision: p.division,
                      providerTypeCategory: p.display_info?.provider_type_category
                    })
                  }
                  // onClick={(e: any) => {
                  //   history.push("/Appointment/" + p.id + "/");
                  // }}
                >
                <FormattedMessage id="appoint.cancel_or_postpone" />
                  {/* ยกเลิก/เลื่อนนัด */}
                </Button>
              )}
            </div>
            <div className="location">
              <Icon name={"map marker alternate"} color={"red"} />
              {p.division_name}
            </div>
            <div className="dateTime">
              <Icon name={"clock outline"} />
              {p.display_info && (
                <>
                  {`${!props.displayDatetimeIso ?
                        serial_to_datestr(
                          p.display_info.start_serial,
                          "localeDate",
                          {
                            weekday: "short",
                            year: "numeric",
                            month: "short",
                            day: "numeric"
                          },
                          {
                            locale: Cookies.get("language")
                          }
                        )
                        : moment(p.display_info.start_datetime_iso).clone().locale("en").format("ddd, MMM DD, YYYY")
                      } 
                  ${createEstimateTime({ time: p.estimate_service_at })}
                  `}
                </>
              )}
            </div>
          </div>
        );
      })}
      {patientAppointmentList.length === 0 && !isLoading && (
        <div className="no-appoint">
          <h1>
            <FormattedMessage id="appoint.no_appointment" />
          </h1>
        </div>
      )}
      </Dimmer.Dimmable>
    </div>
  );
};

TimePatient.defaultProps = {
  patientData: {},
  controller: {},
  division_service_blocks: [],
  noApiToken: () => {},
  header: {},
  displayDatetimeIso: false
};

export default React.memo(TimePatient);
