import BaseService from "./BaseService";
import { PRX } from "../../configs/apis";
import axios from "axios";
import Qs from "qs";
import config from "../../../config/config";
class PRXService extends BaseService {
  /**
   * get operating order
   * @param {object} data
   */
  getDiagRule({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.DIAG_RULE, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagForm({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.DIAG_FORM, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagFormDetail({ params, apiToken, diagFormId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = PRX.DIAG_FORM_DETAIL({ diag_form_id: diagFormId })
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagFormClassify({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.DIAG_FORM_CLASSIFY_CHOICE, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  patchDiagForm({ apiToken, params, diagFormId, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = PRX.DIAG_FORM_DETAIL({ diag_form_id: diagFormId })
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDivisionHasUser({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.DIVISION_HAS_USER, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDivisionProfile({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    // console.log( 'service' , params)
    // console.log( 'service' , apiToken)
    // let url = PRX.DIVISION_PROFILE({ divisionId: division })
    // return this.client.get(url, { params })
    //   .then(this.handleResponse)
    //   .catch(this.throwErrorMessage);
    return this.client
      .get(PRX.DIVISION_PROFILE, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  createDivisionHasUser({ params, apiToken, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.DIVISION_HAS_USER, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagRuleDetail({ params, apiToken, id } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.DIAG_RULE_DETAIL({
      diagRuleId: id
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postDiagRule({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.DIAG_RULE, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postTuhSetPatientHn({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.TUH_SET_PATIENT_HN, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postListPatientFromDiagForm({ params, data, apiToken, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .post(PRX.LIST_PATIENT_FROM_DIAG_FORM, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postListPatientFromPatient({ params, data, apiToken, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .post(PRX.LIST_PATIENT_FROM_PATIENT, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProxyPatientHasDivisionNotFollow({ params, apiToken, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.PROXY_PATIENT_HAS_DIVISION_NOT_FOLLOW, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProxyPatientHasDivisionPatientChat({ params, apiToken, divisionId, patientId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    const url = PRX.PROXY_PATIENT_HAS_DIVISION_PATIENT_CHAT_ID({ patient_id: patientId })
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  patchDiagRuleDetail({ params, data, apiToken, id } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.DIAG_RULE_DETAIL({
      diagRuleId: id
    });
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchDivisionHasUserOA({ params, data, apiToken, id, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    let url = PRX.EDIT_DIVISION_HAS_USER_OA({
      id
    });
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postDiagFormMonitor({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.DIAG_FORM_MONITOR, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  };
  postPublishedDiagRule({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.PUBLISHED_DIAG_RULE, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPublishedDiagRule({ params, apiToken, diagRuleId }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.PUBLISHED_DIAG_RULE_DETAIL({
      publishedDiagRuleId: diagRuleId
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  patchPublishedDiagRule({ params, data, apiToken, id } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.PUBLISHED_DIAG_RULE_DETAIL({
      publishedDiagRuleId: id
    });
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMasterTriageLevelClassify({ params, apiToken } = {}) {
    // if (apiToken){
    //   this.client.defaults.headers["Authorization"] = "Token " + apiToken
    // }
    let axiosWithoutToken = axios.create({
      paramsSerializer: params =>
        Qs.stringify(params, {
          arrayFormat: "repeat"
        })
    });
    // this.axiosWithoutToken.interceptors.request.use(this._interceptRequest, error => Promise.reject(error));
    // // intercept a response error (500) and error
    // this.axiosWithoutToken.interceptors.response.use(res => {
    //   return res
    // }, this._handleError);
    return axiosWithoutToken
      .get(PRX.MASTER_TRIAGE_LEVEL_CLASSIFY, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getClassifyUser({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.CLASSIFY_USER, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getTriageLevelClassify({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    // let axiosWithoutToken = axios.create({
    //   paramsSerializer: params => Qs.stringify(params, {
    //     arrayFormat: 'repeat'
    //   }),
    // })
    // this.axiosWithoutToken.interceptors.request.use(this._interceptRequest, error => Promise.reject(error));
    // // intercept a response error (500) and error
    // this.axiosWithoutToken.interceptors.response.use(res => {
    //   return res
    // }, this._handleError);
    return this.client
      .get(PRX.TRIAGE_LEVEL_CLASSIFY, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getLatestChatChannel({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.LATEST_CHAT_CHANNEL, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getChatChannelMessageList({ params, apiToken, chatChannelId } = {}) {
    console.log('getChatChannelMessageList: ');
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.CHAT_CHANNEL_MESSAGE_LIST({
      chatChannelId
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMessageFromURL({ params, apiToken, url } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postChatChannelMessage({ params, data, apiToken, chatChannelId, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["Content-Type"] = "multipart/form-data";
      this.client.defaults.headers["division"] = divisionId;
    }
    let url = PRX.CHAT_CHANNEL_MESSAGE_POST({
      chatChannelId
    });
    let fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }
    return this.client
      .post(url, fd, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounter({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.ENCOUNTER, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounterTriage({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.ENCOUNTER_TRIAGE, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  geNurseNoteList({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.NURSE_NOTE_LIST, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  geProgressNoteList({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.PROGRESS_NOTE_LIST, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getChannelDetail({ params, apiToken, chatChannelId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.CHAT_CHANNEL_DETAIL({
      chatChannelId
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  geNurseNoteEncounter({ params, apiToken, encounterId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.NURSE_NOTE_ENCOUNTER({
      encounterId
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProxyPatient({ params, apiToken, patientId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.PROXY_PATIENT({
      patientId
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getProxyPatientAllergy({ params, apiToken, patientId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.PROXY_PATIENT_ALLERGY({
      patient_id: patientId
    });
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getProxyPatientList({ params, apiToken, division } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = division;
    }
    let url = PRX.PROXY_PATIENT_LIST;
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchEncounterReclassify({ apiToken, params, data, encounterId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.ENCOUNTER_RECLASSIFY({
      encounterId
    });
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getChatChannel({ params, apiToken, isPatient }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.CHAT_CHANNEL, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProxyPatientHasDivision({ params, apiToken }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.PROXY_PATIENT_HAS_DIVISION, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  patchProxyPatientHasDivision({ apiToken, params, data, id }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.PROXY_PATIENT_HAS_DIVISION_ID({ id });
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  patchProxyPatientHasDivisionAdmin({ apiToken, params, data, id }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.PROXY_PATIENT_HAS_DIVISION_ID_ADMIN({ id });
    return this.client
      .patch(url, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProxyPatientHasDivisionIdAdmin({ apiToken, params, division, id }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = division;
    }
    let url = PRX.PROXY_PATIENT_HAS_DIVISION_ADMIN
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPatient({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.PATIENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getTuhUsersList({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.TUH_USERS_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getProxyPatientHasDivisionAdmin({ params, apiToken, division } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = division;
    }
    let url = PRX.PROXY_PATIENT_HAS_DIVISION_ADMIN
    return this.client
      .get(url, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchPatient({ options, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    console.log("patchPatient (data, option)", data, options)
    return this.client
      .patch(PRX.PATIENT, data, options)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postEncounterPatientAppointment({ apiToken, params, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.ENCOUNTER_PATIENT_APPOINTMENT, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorWorkSchedule({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.DOCTOR_WORK_SCHEDULE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getAvailableDivisionServiceBlock({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.AVAILABLE_DIVISION_SERVICE_BLOCK, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postSelectDivisionServiceBlock({ apiToken, params, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.SELECT_DIVISION_SERVICE_BLOCK, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postRequestChangeDivisionServiceBlock({ apiToken, params, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.REQUEST_CHANGE_DIVISION_SERVICE_BLOCK, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounterTriageDetail({ apiToken, encounterId, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.ENCOUNTER_TRIAGE_DETAIL({ encounterId }), { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getPatientQueue({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.PATIENT_QUEUE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getAppointedPatientQueue({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = config.QUEUE_API_V2 ? PRX.APPOINTED_PATIENT_QUEUE_V2 : PRX.APPOINTED_PATIENT_QUEUE;
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getShippingAddress({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.SHIPPING_ADDRESS, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postShippingAddress({ apiToken, params, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.SHIPPING_ADDRESS, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getInitPatientAddress({ params, apiToken, patientId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.INIT_PATIENT_ADDRESS_PATIENT({ patientId }), { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getDashboard({ params, apiToken, patientId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.DASHBOARD, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getOfficialAccountDashboard({ params, apiToken, patientId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.OA_DASHBOARD, { params })
      .then(response => response.data)
      .catch(this.throwErrorMessage);
  }
  getDashboardAppointment({ apiToken, params, cancelToken }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = config.QUEUE_API_V2 ? PRX.DASHBOARD_APPOINTMENT_V2 :
      config.QUEUE_OA_DASHBOARD_APPOINTMENT ? PRX.OA_DASHBOARD_APPOINTMENT : PRX.DASHBOARD_APPOINTMENT;
    return this.client
      .get(url, { params, cancelToken })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getResolveChatChannel({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.RESOLVE_CHAT_CHANNEL, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }


  postRequestOA({ apiToken, data, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.CREATE_REQUEST_OA, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }


  getListOA({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.LIST_OA, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postFollowOA({ apiToken, data, params, id } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.FOLLOW_OA({ id });
    return this.client
      .post(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getOAInviteLink({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.GET_OA_INVITE_LINK, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }


  postCreatejoinOARequest({ apiToken, data, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.CREATE_JOIN_OA_REQUEST, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListJoinOARequest({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.LIST_JOIN_OA_REQUEST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListUserRoleOA({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.LIST_USER_ROLE_OA, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }


  postCreateUpdateSchedule({ apiToken, data, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.CREATE_UPDATE_SCHEDULE, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postApproveJoinOARequest({ apiToken, data, params, id, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }
    let url = PRX.APPROVE_JOIN_OA_REQUEST({ id });
    return this.client
      .post(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  deleteJoinOARequest({ apiToken, data, params, id, divisionId }) {

    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }
    let url = PRX.DELETE_JOIN_OA_REQUEST({ id });
    return this.client
      .delete(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  deleteDivisionHasUserOA({ apiToken, data, params, id, divisionId }) {

    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }
    let url = PRX.EDIT_DIVISION_HAS_USER_OA({ id });
    return this.client
      .delete(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListDivisionHasUserOA({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.LIST_DIVISION_HAS_USER_OA, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListSchedule({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.PRX_SCHEDULE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getOADivisionProfile({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.GET_OA_DIVISION_PROFILE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListOARequestCheck({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.LIST_JOIN_OA_CHECK, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }


  getOAUserProfile({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.OA_USER_PROFILE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListAvailabelTraigeLevel({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client
      .get(PRX.LIST_AVAILABLE_TRAIGE_LEVEL, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }


  getDivisionClassify({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(PRX.LIST_DIVISION_CLASSIFY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getChatChannelTriageLevel({ apiToken, params, divisionId, triageLevel } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }

    let url = PRX.LIST_CHAT_CHANNEL_TRIAGE_LEVEL({ triage_level: triageLevel });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListAvailabelTriageLevelDiagRule({ apiToken, params, divisionId, diagRuleId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }

    let url = PRX.LIST_AVAILABEL_TRAIGE_LEVEL_DIAGRULE({ diag_rule_id: diagRuleId })
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListChatChannelTriageLevelDiagRule({ apiToken, params, divisionId, diagRuleId, triageLevel } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }

    let url = PRX.LIST_CHAT_CHANNEL_TRIAGE_LEVEL_DIAGRULE({ diag_rule_id: diagRuleId, triage_level: triageLevel })
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }



  patchOAUserProfile({ apiToken, params, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["Content-Type"] = "multipart/form-data";
    }

    let fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }

    return this.client
      .patch(PRX.OA_USER_PROFILE, fd, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchOADivisionProfile({ apiToken, params, data, divisionId } = {}) {

    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
      this.client.defaults.headers["Content-Type"] = "multipart/form-data";
    }

    let fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }

    return this.client
      .patch(PRX.GET_OA_DIVISION_PROFILE, fd, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postPatientFromDiagRule({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.PATIENT_FROM_DIAGRULE, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getPatientFromDiagRule({ apiToken, params, patientId, diagRuleId, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
      this.client.defaults.headers["division"] = divisionId;
    }

    let url = PRX.PATIENT_FROM_DIAGRULE_BY_ID({ patient_id: patientId, diag_rule_id: diagRuleId })
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putPatientFromDiagRule({ apiToken, patientId, diagRuleId, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    let url = PRX.PATIENT_FROM_DIAGRULE_BY_ID({ patient_id: patientId, diag_rule_id: diagRuleId })
    return this.client.put(url, data)
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  getVaccineHospital({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    return this.client
      .get(PRX.LIST_VACCINE_HOSP, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getAvailableVaccineDivision({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    return this.client
      .get(PRX.LIST_AVAILABLE_VACCINE_DIVISION, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postVaccineServiceBlock({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.VACCINE_SERVICE_BLOCK, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postVaccineNote({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .post(PRX.VACCINE_NOTE, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putVaccineServiceBlock({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .put(PRX.VACCINE_SERVICE_BLOCK, data, {
        params
      })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  deleteVaccineServiceBlock({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = PRX.DEL_VACCINE_SERVICE_BLOCK({ division_service_block: params.division_service_block })
    return this.client
      .delete(url, {})
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListVaccine({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    return this.client
      .get(PRX.LIST_VACCINE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListVaccineLot({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    return this.client
      .get(PRX.LIST_VACCINE_LOT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListVaccineNote({ apiToken, params, divisionId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = PRX.VACCINE_NOTE_FIREBASE({ division_id: divisionId })
    return this.client
      .get(url, {})
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getVaccineNoteDetail({ apiToken, params, vaccineId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    const url = PRX.VACCINE_NOTE_ID({ vaccine_id: vaccineId })
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  patchVaccineNote({ apiToken, data, id } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    const url = PRX.VACCINE_NOTE_ID({ vaccine_id: id })
    return this.client
      .patch(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }


  getTagEconsentOptions({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = PRX.TAG_OPTIONS
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getV2EncounterTriage({ apiToken, params } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    const url = PRX.V2_ENCOUNTER_TRIAGE
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postRequestBotMessage({ apiToken, data, chat_channel_id, params} = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }

    const url = PRX.REQUEST_BOT_MESSAGE({ chat_channel_id: chat_channel_id })
    return this.client
      .post(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getOfficialAccountDoctor({ apiToken, divisionId, params }: any = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    if (divisionId) {
      this.client.defaults.headers["division"] = divisionId;
    }
    return this.client.get(PRX.OFFICIAL_ACCOUNT_DOCTOR, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }


}
export default PRXService;
