import React, { CSSProperties, useState, useEffect} from "react";
// @ts-ignore
import Cookies from "js-cookie";
import CONFIG from "config/config";

// CSS
import {
  Input,
  Button,
  Divider,
  Icon,
  Form,
  InputProps,
  Dimmer,
  Loader,
} from "semantic-ui-react";

// Interface
import {
  Event as MobEvent,
  State as MobState,
  StateInitial as MobStateInitial,
} from "../../../../patient-lib/MobTransformInterface";

// UI
import ModMessage from "./ModMessage";

type TransformLoginProps = {
  onEvent: (e: MobEvent) => any;
  history: any;
  deviceId: string;
  onHaveApiToken?: (history: any) => any;
} & Pick<
  MobState,
  | "inputErrorLogin"
  | "loadingMainTransform"
  | "errorMessage"
  | "successMessage"
  | "setDevice"
  | "deviceId"
  | "userSubscription"
>;

const COLOR = {
  primary: "var(--primary-theme-color)",
  primary_font: "var(--primary-font-color)",
  secondary: "#F2C94C",
  violet_blue: "#2D247F",
  font_white: "white",
  orange_light: "#EB5757",
};

const ICON = {
  tuh: "/tuh-transform-icon.png",
  // egat: "/egat-logo.png",
  egat: "/logo_ishealth.png",
};

const styles = {
  box_icon: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "2rem 0 0",
    minHeight: "100px",
  } as CSSProperties,
  text_login: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "20px",
    color: COLOR.primary_font,
    marginTop: "10px",
  } as CSSProperties,
  label: {
    color: COLOR.primary_font,
    fontSize: "16px",
    marginBottom: "5px",
  } as CSSProperties,
  text_action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "7% 0px 9%",
  } as CSSProperties,
  button: {
    background: COLOR.primary,
    color: COLOR.font_white,
    fontSize: "16px",
    padding: "15px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  } as CSSProperties,
  divider: {
    margin: "9.5% 0px",
    color: COLOR.primary,
    textTransform: "unset",
    fontSize: "18px",
  } as CSSProperties,
  icon: {
    color: COLOR.font_white,
    width: "30px",
    fontSize: "20px",
    marginBottom: "-2px",
  } as CSSProperties,
};

const TransformLogin: React.FunctionComponent<TransformLoginProps> = (
  props
) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  useEffect(() => {
    if (Cookies.get("apiToken")) {
      props.onHaveApiToken?.(props.history);
    }
  }, []);

  useEffect(() => {
    // call window.iosNative.getDevice() success
    //  setDevice from native
    if (props.setDevice?.device_token) {
      props.onEvent({
        message: "HandleSignIn",
        params: { username, password, deviceId: props.deviceId, userSubscription: props.userSubscription, history: props.history },
      });
    }
  }, [props.setDevice]);

  /* ----------------------- Handle ----------------------- */
  const handleRegister = () => {
    props.history.push("/tuh-transform/register");
  };

  const handleForgetPassword = () => {
    props.history.push("/tuh-transform/password?action=request");
  };

  const handleSignInEmail = () => {
    props.history.push("/tuh-transform/sign-in-email");
  };

  const handleSignIn = () => {
    props.onEvent({
      message: "HandleSignIn",
      params: { username, password, deviceId: props.deviceId, userSubscription: props.userSubscription, history: props.history },
    });
  };

  return (
    <div>
         { CONFIG.COMPANY === "EGAT" ? <div className="box-logo-login" style={{ ...styles.box_icon, alignItems: "flex-end" , height: "auto",padding: "0 0 2rem"}}>
           <img
           src={ICON.egat}
           alt={"EGAT-icon"}
           style={{ width: "110%" ,paddingBottom: "0rem"}}
         /> 
      </div> : null }
      
    <div className="tf-register tf-main" style={{ overflowY: "auto" }}>
      <Dimmer active={props.loadingMainTransform} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

    {console.log('chen ',CONFIG.COMPANY === "TU" ? "tu" : "egat")}
     {CONFIG.COMPANY === "TU" ? <div className="box-logo-login" style={styles.box_icon}>
        { CONFIG.COMPANY === "TU" ? 
          <img
            src={ICON.tuh}
            alt={"tuh-transform-icon"}
            style={{ width: "40%", maxWidth: "250px" }}
          /> :  
          <img
          src={ICON.egat}
          alt={"EGAT-icon"}
          style={{ width: "100%" ,paddingBottom: "0rem"}}
        />
        }
      </div> : null}


      <div style={styles.text_login}>ลงชื่อเข้าใช้งาน</div>

      {/* Form Login */}
      <div style={{ marginTop: "9%" }}>
        <Form onSubmit={handleSignIn}>
          <Form.Field error={props.inputErrorLogin?.username || false}>
            <label style={styles.label}>ชื่อผู้ใช้งาน</label>
            <Input
              value={username}
              icon
              placeholder=""
              style={{ fontSize: "16px" }}
              onChange={(e: any, v: InputProps) => setUsername(v.value)}
            >
              <input />
              <Icon
                name="user circle outline"
                style={{ fontSize: "1.3rem", marginRight: "-5px" }}
              />
            </Input>
          </Form.Field>
          <Form.Field error={props.inputErrorLogin?.password || false}>
            <label style={styles.label}>รหัสผ่าน</label>
            <Input
              value={password}
              icon={{
                name: !hidePassword ? "eye" : "eye slash",
                link: true,
                onClick: (e: any) => setHidePassword(!hidePassword),
              }}
              style={{ fontSize: "16px" }}
              placeholder=""
              type={!hidePassword ? "input" : "password"}
              onChange={(e: any, v: InputProps) => setPassword(v.value)}
            />
          </Form.Field>
          <button hidden></button>
        </Form>
        {props.inputErrorLogin?.message && (
          <div
            style={{
              textAlign: "right",
              marginTop: "5px",
              color: COLOR.orange_light,
              fontSize: "12px",
            }}
          >
            {props.inputErrorLogin?.message || ""}
          </div>
        )}
      </div>

      {/*  */}
      <div style={styles.text_action}>
        <label
          style={{
            color: COLOR.primary_font,
            fontWeight: "bold",
            fontSize: "17px",
          }}
          onClick={handleRegister}
        >
          ลงทะเบียน
        </label>
        <label
          style={{ color: COLOR.primary_font, fontWeight: "bold", fontSize: "17px" }}
          onClick={handleForgetPassword}
        >
          ลืมรหัสผ่าน
        </label>
      </div>

      <Button fluid style={styles.button} onClick={handleSignIn}>
        ลงชื่อเข้าใช้งาน
      </Button>

      <Divider horizontal style={styles.divider}>
        or
      </Divider>

      { CONFIG.COMPANY === "TU" ? 
      <Button
        fluid
        style={{ ...styles.button, background:  COLOR.secondary }}
        onClick={handleSignInEmail}
      >
        <Icon name="mail outline" style={styles.icon} />
        ลงชื่อเข้าใช้งานด้วย Email
      </Button> : 
      <button
        fluid
        style={{ ...styles.button, background: "#FFFFFF" , color: COLOR.primary , borderWidth: "2px" , borderColor: COLOR.primary ,width: "100%"}}
        onClick={handleSignInEmail}
      >
        <Icon name="mail outline" style={styles.icon} />
        ลงชื่อเข้าใช้งานด้วย Email
      </button>

      }
      
      <ModMessage
        onEvent={props.onEvent}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
      />
    </div>
    </div>
  );
};

TransformLogin.defaultProps = {
  history: {},
  onEvent: () => {},
  inputErrorLogin: MobStateInitial.inputErrorLogin,
  loadingMainTransform: false,
  errorMessage: null,
  successMessage: null,
  deviceId: "",
  userSubscription: {},
} as TransformLoginProps;

export default React.memo(TransformLogin);
