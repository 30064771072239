import QUEService from "../services/QUEService";
import { to } from "../../utils";
export default class QUEManager {
  createServiceSlot = async ({
    apiToken,
    serial,
    status,
    provider,
    division
  } = {}) => {
    let params = {};
    let data = {};
    if (serial) {
      data.serial = serial;
    }
    if (status) {
      data.status = status;
    }
    if (provider) {
      data.provider = provider;
    }
    if (division) {
      data.division = division;
    }
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.createServiceSlot({
        apiToken,
        data,
        params
      })
    );
    return [response, error, network];
  };
  updateServiceSlot = async ({
    apiToken,
    pk,
    serial,
    status,
    provider,
    division
  } = {}) => {
    let params = {};
    let data = {};
    if (serial) {
      data.serial = serial;
    }
    if (status) {
      data.status = status;
    }
    if (provider) {
      data.provider = provider;
    }
    data.division = division; // if it is null, send null; Otherwise, it wont inactive
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.updateServiceSlot({
        apiToken,
        pk,
        data,
        params
      })
    );
    return [response, error, network];
  };
  loadServiceSlot = async ({ apiToken, fromSerial, toSerial, providerId } = {}) => {
    let params = {};
    if (fromSerial) {
      params.from_serial = fromSerial;
    }
    if (toSerial) {
      params.to_serial = toSerial;
    }
    if (providerId) {
      params.provider_id = providerId;
    }
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.loadServiceSlot({
        apiToken,
        params
      })
    );
    return [response, error, network];
  };
  loadDivisionServiceBlock = async ({
    apiToken,
    from_serial,
    to_serial,
    divisions,
    onlyHasProvider,
    employeeType
  } = {}) => {
    let params = {};
    if (from_serial) {
      params.from_serial = from_serial;
    }
    if (to_serial) {
      params.to_serial = to_serial;
    }
    if (divisions) {
      params.divisions = divisions;
    }
    if (onlyHasProvider) {
      params.only_has_provider = onlyHasProvider;
    }
    if (employeeType) {
      params.employee_type = employeeType;
    }
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.loadDivisionServiceBlock({
        apiToken,
        params
      })
    );
    return [response, error, network];
  };
  getDivisionServiceBlockDetail = async ({ apiToken, pk } = {}) => {
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.getDivisionServiceBlockDetail({
        apiToken,
        pk
      })
    );
    return [response, error, network];
  };
  listPatientAppointment = async ({ apiToken, patientId, status} = {}) => {
    console.log("listPatientAppointment: ", apiToken, patientId, status)
    let params = {};
    if (patientId) {
      params.patient_id = patientId;
    }
    if (status){
      params.status = status
    }
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.listPatientAppointment({
        apiToken,
        params
      })
    );
    return [response, error, network];
  };
  getPatientAppointment = async ({ apiToken, pk } = {}) => {
    let params = {};
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.getPatientAppointment({
        apiToken,
        pk,
        params
      })
    );
    return [response, error, network];
  };
  updatePatientAppointment = async ({ apiToken, pk, divisionServiceBlock } = {}) => {
    let params = {};
    let data = {};
    if (divisionServiceBlock) {
      data.division_service_block = divisionServiceBlock;
    }
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.updatePatientAppointment({
        apiToken,
        pk,
        data,
        params
      })
    );
    return [response, error, network];
  };
  getPatientAppointmentUpdate = async ({ apiToken, appointmentId } = {}) => {
    let params = {};
    const queService = new QUEService();
    const [error, response, network] = await to(
      queService.getPatientAppointmentUpdate({
        apiToken,
        appointmentId,
        params
      })
    );
    return [response, error, network];
  };
}
