import React, { useState } from 'react';
import { Icon } from "semantic-ui-react";
import { useCookies } from "react-cookie";

const RegisterEmailSuccess = props => {
  const [cookies] = useCookies([]);

  React.useEffect(() => {
    if(cookies.apiToken){
       props.onHaveApiToken()
    }
  }, [cookies.apiToken])

  return (
    <div className="email-register-success">
    <div className="green">
      <Icon name="check" size="massive" />
    </div>
    <h1 className="green">
      Complete !
    </h1>
    <br/>
    <h4 className="sub-text">
      ท่านลงทะเบียนเพื่อขอเริ่มใช้งานสำเร็จแล้ว
      กรุณาตรวจสอบ E-mail เพื่อเริ่มใช้งาน
    </h4>
    </div>
  )
}

RegisterEmailSuccess.defaultProps = {
  onHaveApiToken: () => {},
};

RegisterEmailSuccess.propTypes = {
  onHaveApiToken: () => {},
};


export default RegisterEmailSuccess
