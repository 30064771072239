import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import EmailLoginManager from "./EmailLoginManager";

const Callback = props => {

    const manager = new EmailLoginManager()
    const params = new URLSearchParams(useLocation().search)
    const accessToken = params.get('access_token')
    const email = params.get('email')
    let [loading, setLoading] = useState(true)
    let [data, setData] = useState({})
    let [message, setMessage] = useState('')
    
    const requestToken = async () => {
        try {
            let ret = await manager.getTokenFromAccount(email, accessToken)
            setMessage('')
            console.log(ret)
            setData(ret)
            setLoading(false)
            props.onLoginSuccess(ret)
        } catch (e) {
            setMessage(JSON.stringify(e.response.data))
            setLoading(false)
            props.onLoginFailed()
        }
    }

    useEffect(() => {
        requestToken()
    }, [])

    return (
      <></>
    )
}

export default Callback;